export const MAILCHIMP_METRICS = [
    "received",
    "opens",
    "openRate",
    "clicks",
    "clickRate",
    "bounces",
    "bounceRate",
    "unsubscribes",
    "unsubscribeRate",
    "spams",
    "spamRate",
    "totalOrders",
    "totalRevenue",
    "totalAov",
];

export const MAILCHIMP_EXCLUDE_EXPORT_FIELDS = [
    "status",
    "sendTime",
    "emailsSent",
    "audience",
    "recipients",
    "accountId",
    "integrationId",
    "currency",
];
