import types from "./types";
import { getCampaignReport } from "lib/mailchimp/api";

const getMailchimpData = ({ dateRange, accountId, abortController }) => (dispatch) => {
    const [{ start, end }] = dateRange || {};
    return dispatch({
        type: types.MAILCHIMP_REPORT,
        promise: getCampaignReport({
            accountId,
            startDate: start,
            endDate: end,
            abortController,
        }),
    });
};

export default {
    getMailchimpData,
};
