const REPORT_SETTINGS = "REPORT_SETTINGS";
const GET_REPORT_DATA = "GET_REPORT_DATA";
const GET_PAID_PLATFORMS_REPORT_DATA = "GET_PAID_PLATFORMS_REPORT_DATA";
const GET_CAMPAIGN_GROUPS_REPORT_DATA = "GET_CAMPAIGN_GROUPS_REPORT_DATA";
const GET_GA4_REPORT_DATA = "GET_GA4_REPORT_DATA";
const GET_MAROPOST_REPORT_DATA = "GET_MAROPOST_REPORT_DATA";
const GET_KLAVIYO_REPORT_DATA = "GET_KLAVIYO_REPORT_DATA";
const GET_SHOPIFY_REPORT_DATA = "GET_SHOPIFY_REPORT_DATA";
const GET_ORGANIC_INSTAGRAM_REPORT_DATA = "GET_ORGANIC_INSTAGRAM_REPORT_DATA";
const REPORT_BREADCRUMB = "REPORT_BREADCRUMB";
const SET_CAMPAIGNS = "SET_CAMPAIGNS";
const REPORT_CLEAR = "REPORT_CLEAR";
const GET_AD_GROUP_REPORTS = "GET_AD_GROUP_REPORTS";
const GET_AD_GROUPS_INFO = "GET_AD_GROUPS_INFO";
const UPDATE_AD_GROUP = "UPDATE_AD_GROUP";
const GET_AD_REPORTS = "GET_AD_REPORTS";
const GET_ADS_INFO = "GET_ADS_INFO";
const UPDATE_AD = "UPDATE_AD";
const SET_ADGROUPS = "SET_ADGROUPS";
const SET_ADS = "SET_ADS";
const RESET_AD_REPORTS = "RESET_AD_REPORTS";
const FETCH_LISTS_ADGROUPS = "FETCH_LISTS_ADGROUPS";
const GET_CAMPAIGN_GROUPS_INFO = "GET_CAMPAIGN_GROUPS_INFO";
const SET_CAMPAIGNS_FILTER = "SET_CAMPAIGNS_FILTER";
const UPDATE_PLATFORM_CAMPAIGN_ANALYSIS = "UPDATE_PLATFORM_CAMPAIGN_ANALYSIS";
const CLEAR_ADGROUPS_DATA_LOADING = "CLEAR_ADGROUPS_DATA_LOADING";
const CLEAR_ADS_DATA_LOADING = "CLEAR_ADS_DATA_LOADING";
const FETCH_LISTS_ADS = "FETCH_LISTS_ADS";

export default {
    REPORT_SETTINGS,
    GET_REPORT_DATA,
    GET_PAID_PLATFORMS_REPORT_DATA,
    GET_CAMPAIGN_GROUPS_REPORT_DATA,
    GET_GA4_REPORT_DATA,
    GET_MAROPOST_REPORT_DATA,
    GET_KLAVIYO_REPORT_DATA,
    GET_SHOPIFY_REPORT_DATA,
    GET_ORGANIC_INSTAGRAM_REPORT_DATA,
    REPORT_BREADCRUMB,
    SET_CAMPAIGNS,
    REPORT_CLEAR,
    GET_AD_GROUP_REPORTS,
    GET_AD_GROUPS_INFO,
    UPDATE_AD_GROUP,
    GET_AD_REPORTS,
    GET_ADS_INFO,
    UPDATE_AD,
    SET_ADGROUPS,
    SET_ADS,
    RESET_AD_REPORTS,
    FETCH_LISTS_ADGROUPS,
    GET_CAMPAIGN_GROUPS_INFO,
    SET_CAMPAIGNS_FILTER,
    UPDATE_PLATFORM_CAMPAIGN_ANALYSIS,
    CLEAR_ADGROUPS_DATA_LOADING,
    CLEAR_ADS_DATA_LOADING,
    FETCH_LISTS_ADS,
};
