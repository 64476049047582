import { KpiAPI } from "lib/apiKpi";
import { getSelectedWorkspaceId } from "lib/workspaces";
import { formatDateToString } from "lib/dateUtils";

function getCampaigns({ userId, connectionId }) {
    const workspaceId = getSelectedWorkspaceId();

    return KpiAPI({
        method: "GET",
        url: `/mailchimp/campaigns`,
        query: {
            userId: workspaceId || userId,
            connectionId,
        },
    })
        .then(({ data }) => {
            return {
                connectionId,
                campaigns: {
                    mailchimp: data,
                },
            };
        })
        .catch((error) => ({ data: { error: true } }));
}

export function getCampaignReport({ startDate, endDate, accountId, abortController }) {
    if (!accountId || !startDate || !endDate) {
        return [];
    }

    return KpiAPI({
        method: "GET",
        url: "/mailchimp/reports",
        query: {
            accountId,
            startDate: formatDateToString(startDate),
            endDate: formatDateToString(endDate),
        },
        abortController,
    }).then(({ data = [] } = {}) => {
        return data;
    });
}

export default {
    getCampaigns,
    getCampaignReport,
};
